import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { sizeStyles } from './sizeStyles';
import { colorStyles } from './colorStyles';
import { flexGap } from 'style/css-style';

const Icon = styled.div`
  display: flex;
`;

const Label = styled.p`
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${flexGap(4)}

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  border-radius: 8px;

  .tag-label {
    flex: 1;
  }

  &.font-weight-bold {
    font-weight: 700;
  }

  ${sizeStyles}
  ${colorStyles}
`;

export const TagLabel = props => {
  const {
    className,
    size = '24px',
    color = 'gray',
    colorStyle = 'light',
    fontWeight = 'default',
    text,
    iconLeft,
    iconRight
  } = props;

  return (
    <Container
      className={cl(
        'tag-label',
        `size-${parseInt(size, 10)}`,
        `color-${color}`,
        `color-style-${colorStyle}`,
        `font-weight-${fontWeight}`,
        className
      )}
    >
      {iconLeft && <Icon className="tag-icon-left">{iconLeft}</Icon>}
      <Label className="tag-label">{text}</Label>
      {iconRight && <Icon className="tag-icon-right">{iconRight}</Icon>}
    </Container>
  );
};

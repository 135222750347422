import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import {
  IconsEssentialSearchStar,
  IconsTimeCalendarScheduleTime1,
  IconsUserGroup,
  IconsGraphicAnchorSelectEdit
} from 'components/Icon/SVGIcons';
import { SpecialCard } from './components/SpecialCard';
import { LazyImage } from 'components/LazyImage';
import Modal from '../CourseLanding/Modal';
import VideoInline from 'shared/VideoThumbnailInline/VideoInline';
import { fromScreen } from 'utils/media-query/responsive.util';
import { graphql, useStaticQuery } from 'gatsby';

const exploreIcon = {
  'năm nghiên cứu': <IconsEssentialSearchStar color={colorsV2.white80} />,
  'năm hoàn thiện': <IconsTimeCalendarScheduleTime1 color={colorsV2.white80} />,
  'chuyên gia': <IconsUserGroup color={colorsV2.white80} />,
  'lần chỉnh sửa': <IconsGraphicAnchorSelectEdit color={colorsV2.white80} />
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 64px 0;
  gap: 48px;
  ${fromScreen(458)} {
    gap: 64px;
  }
  ${fromScreen(776)} {
    padding: 100px 0;
  }
  ${fromScreen(1144)} {
    grid-template-columns: 1fr minmax(0, 352px);
    gap: 0;
  }
`;

const LeftSide = styled.div`
  margin-top: 0;
  ${fromScreen(1144)} {
    margin-top: 21px;
  }
`;
const RightSide = styled.div`
  .book-thumbnail {
    width: 100%;
    margin: auto;
  }
  img {
    border-radius: 12px;
    cursor: pointer;
  }
  ${fromScreen(776)} {
    .book-thumbnail {
      width: 432px;
      margin: auto;
    }
  }
  ${fromScreen(1144)} {
    .book-thumbnail {
      width: 100%;
      margin: 0;
    }
  }
`;
const TextWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 48px;
  .explore-title {
    width: 100%;
  }
  ${fromScreen(776)} {
    .explore-title {
      width: 415px;
    }
  }
  ${fromScreen(1144)} {
    width: 512px;
    .explore-title {
      width: 100%;
    }
  }
`;

const SpecialList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  margin-right: 0;
  ${fromScreen(776)} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${fromScreen(1144)} {
    grid-template-columns: repeat(2, 1fr);
    margin-right: 38px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.white10};
`;

const VideoElement = styled(VideoInline)`
  video {
    border-radius: 8px;
  }
  > div {
    margin-bottom: 0;
  }
`;

const BookExplorationBase = ({ data }) => {
  const { specialThings, videoUrl, videoThumbnailUrl } = data || {};
  const [showModal, setShowModal] = React.useState(false);
  return (
    <Container>
      <LeftSide>
        <TextWrapper>
          <Typography
            className="explore-title"
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            color={colorsV2.white100}
            v3
          >
            Điều gì khiến giáo trình của DOL trở nên đặc biệt!
          </Typography>
          <SpecialList>
            {specialThings?.map((eachSpecialThing, idx) => (
              <SpecialCard
                key={idx}
                quantity={eachSpecialThing?.quantity}
                icon={exploreIcon[eachSpecialThing?.text]}
                text={eachSpecialThing?.text}
              />
            ))}
          </SpecialList>
          <Divider />
          <Typography variant="regular/16-24" color={colorsV2.white80} v3>
            Đằng sau mỗi trang sách là hàng năm nghiên cứu, thử nghiệm và cải
            tiến. Bộ giáo trình của DOL English không chỉ là tài liệu học, mà là
            một sản phẩm của trí tuệ, sự tư duy về cả phương pháp học và thiết
            kế sách. DOL tự hào không vay mượn, cắt ghép sách nước ngoài, mà nội
            dung trong sách đều do DOL soạn thảo theo phương pháp Linearthinking
            độc quyền.
          </Typography>
        </TextWrapper>
      </LeftSide>
      <RightSide>
        <LazyImage
          className="book-thumbnail"
          src={videoThumbnailUrl}
          alt="book-thumbnail"
          onClick={() => setShowModal(true)}
        />
      </RightSide>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoElement
          onContextMenu={e => e.preventDefault()}
          video={videoUrl}
          alt="dol-books"
          controlsList="nodownload"
          isAutoPlay
        />
      </Modal>
    </Container>
  );
};

export const BookExploration = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Dol_Book_Exploration" }) {
            content
          }
        }
      }
    `);
    return <BookExplorationBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.black100
    }
  },
  {}
);

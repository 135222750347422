import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import IconInfoLinearthinkingAmbassador from './icons/IconInfoLinearthinkingAmbassador';
import IconsCourseContent from './icons/IconsCourseContent';
import IconsGraphicColorPalette from './icons/IconsGraphicColorPalette';
import IconsFeatureBulb from './icons/IconsFeatureBulb';
import { FeaturedCard } from './components/FeaturedCard';
import { fromScreen } from 'utils/media-query/responsive.util';
import { graphql, useStaticQuery } from 'gatsby';

const featuredIcon = {
  'Tính tư duy': <IconInfoLinearthinkingAmbassador />,
  'Tính nguyên bản': <IconsCourseContent />,
  'Thiết kế tối ưu': <IconsGraphicColorPalette />,
  'Tính khoa học': <IconsFeatureBulb />
};

const featuredTitleColor = {
  'Tính tư duy': colorsV2.primary100,
  'Tính nguyên bản': colorsV2.blue100,
  'Thiết kế tối ưu': colorsV2.CA100,
  'Tính khoa học': colorsV2.green100
};

const Container = styled.div`
  display: grid;
  gap: 40px;
`;

const HeaderTitle = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  ${fromScreen(776)} {
    width: 572px;
  }
`;
const FeaturedList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  row-gap: 48px;
  ${fromScreen(1144)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const BookFeaturedBase = ({ data }) => {
  return (
    <Container>
      <HeaderTitle>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Những điểm nổi bật của bộ giáo trình mà DOL tạo ra
        </Typography>
      </HeaderTitle>
      <FeaturedList>
        {data?.map((eachFeatured, idx) => {
          const { imageUrl, imageAlt, type, title, description } =
            eachFeatured || {};
          return (
            <FeaturedCard
              key={idx}
              imageUrl={imageUrl}
              imageAlt={imageAlt}
              icon={featuredIcon[type]}
              type={type}
              title={title}
              description={description}
              color={featuredTitleColor[type]}
            />
          );
        })}
      </FeaturedList>
    </Container>
  );
};

export const BookFeatured = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Dol_Book_Featured" }) {
            content
          }
        }
      }
    `);

    return <BookFeaturedBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);

import * as React from 'react';
import Button from '@dolstaff/shared/es/Button';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageFullScreen } from 'components/withHomePageFullScreen';
import { colorsV2 } from 'style/colors-v2';
import { TagLabel } from 'shared/TagLabel/TagLabel';
import { flexGap } from 'style/css-style';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 38px;
  padding: 48px 0;
`;

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${flexGap(24, 'column')};
  margin: auto;
  text-align: center;
  .tag-header {
    width: fit-content;
    border-radius: 38px;
  }
  .info {
    border-radius: 12px;
  }
  ${fromScreen(776)} {
    width: 515px;
  }
`;
const HeaderScroll = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  img {
    height: 192px;
    object-fit: cover;
  }
  ${fromScreen(776)} {
    img {
      height: 371px;
    }
  }
`;

const MaskLeft = styled.div`
  position: absolute;
  height: 100%;
  width: 104px;
  top: 0;
  background: linear-gradient(90deg, #fff9f1 0%, rgba(255, 249, 241, 0) 100%);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(1144)} {
    width: 395px;
  }
`;

const MaskRight = styled.div`
  position: absolute;
  height: 100%;
  width: 104px;
  right: -2px;
  top: 0;
  background: linear-gradient(90deg, #fff9f1 0%, rgba(255, 249, 241, 0) 100%);
  transform: rotate(180deg);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(1144)} {
    width: 395px;
  }
`;

const Header = () => {
  const onScrollBooks = () => {
    const allBooks = document.getElementById('all-dol-books');
    if (allBooks) {
      allBooks.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };
  return (
    <Container>
      <HeaderTitle>
        <TagLabel
          className="tag-header"
          text="Giáo trình độc quyền của DOL"
          color="primary"
        />
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Bộ sách giáo trình
          <br />
          in màu, độc quyền tại DOL
        </Typography>
        <Button
          size="large"
          className="btn-action info"
          type="primary"
          marginBottom="0"
          onClick={onScrollBooks}
        >
          <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
            Tìm hiểu thêm
          </Typography>
        </Button>
      </HeaderTitle>
      <HeaderScroll>
        <LazyImage
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/4e2a4f86-e9af-4183-86f5-c9d2e947adff.png"
          alt="book-header-image"
        />
        <MaskLeft />
        <MaskRight />
      </HeaderScroll>
    </Container>
  );
};

export const BookHeader = withHomePageFullScreen(
  () => {
    return <Header />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {
    style: {
      maxWidth: '1366px'
    }
  }
);

/* eslint-disable react/prop-types */

import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';

const Main = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  gap: 24px;
`;
const Content = styled.div`
  display: grid;
  gap: 12px;
`;
const IconWithText = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  gap: 12px;
  svg {
    width: 30px;
    height: 32px;
  }
`;

export const FeaturedCard = ({
  imageUrl,
  imageAlt,
  icon,
  type,
  title,
  description,
  color = colorsV2.black100
}) => {
  return (
    <Main>
      <LazyImage src={imageUrl} alt={imageAlt} />
      <Content>
        <IconWithText>
          {icon}
          <Typography variant="semi-bold/18-24" color={color} v3>
            {type}
          </Typography>
        </IconWithText>
        <Typography variant="semi-bold/24-32" color={colorsV2.black100} v3>
          {title}
        </Typography>
        <Typography variant="regular/16-24" color={colorsV2.neutral120} v3>
          {description}
        </Typography>
      </Content>
    </Main>
  );
};

import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { useKeenSlider } from 'keen-slider/react';
import { fromScreen } from 'utils/media-query/responsive.util';
import cl from 'classnames';

const ListBook = styled.div`
  margin-top: 24px;
  display: flex;
  ${fromScreen(1144)} {
    display: none;
  }
`;

const BookItem = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: minmax(0, 1fr);
  margin: 0;
  grid-auto-rows: max-content;
`;
const Skills = styled.div`
  display: flex;
  align-items: center;
`;
const ImageBox = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 336px;
  border-radius: 12px;
  background: ${colorsV2.neutral10};
`;

const CustomImage = styled.div`
  position: relative;
  width: 185px;
  box-shadow: 0px 7.52px 7.52px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: width 200ms ease-in-out;
  img {
    border-radius: 8px;
  }
  :hover {
    width: 193px;
  }
`;

const BindingShadowDouble = styled.div`
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  border-radius: 38px;
  opacity: 0.55;
  background: linear-gradient(
    270deg,
    rgba(136, 124, 200, 0) 0%,
    rgba(40, 24, 125, 0.3) 35.42%,
    rgba(40, 24, 125, 0.3) 64.06%,
    rgba(136, 124, 200, 0) 100%
  );
  filter: blur(0.59px);
  left: 7.63px;
`;
const ContentBox = styled.div``;
const Dot = styled.span`
  color: ${colorsV2.gray40};
  margin: 0 8px;
`;

export const ListBookMobile = ({ books = [] }) => {
  const [sliderRef] = useKeenSlider({
    slidesPerView: 1.5,
    spacing: 24,
    breakpoints: {
      '(min-width: 776px)': {
        slidesPerView: 2.5
      }
    },
    loop: true,
    duration: 500,
    mode: 'free-snap'
  });

  return (
    <ListBook ref={sliderRef} className="keen-slider">
      {books?.map((eachBook, idx) => {
        const {
          imageUrl,
          title,
          skills = [],
          isComingSoon = false
        } = eachBook || {};
        return (
          <BookItem key={idx} className={cl('keen-slider__slide')}>
            <ImageBox>
              <CustomImage>
                <LazyImage src={imageUrl} alt={`dol-book-${idx}`} />
                <BindingShadowDouble />
              </CustomImage>
            </ImageBox>
            {isComingSoon ? (
              <Typography
                variant="semi-bold/20-28"
                color={colorsV2.neutral120}
                v3
              >
                Sách sắp ra mắt...
              </Typography>
            ) : (
              <ContentBox>
                <Typography
                  variant="semi-bold/20-28"
                  color={colorsV2.black100}
                  v3
                >
                  {title}
                </Typography>
                <Skills>
                  {skills?.map((eachSkill, skillIdx) => (
                    <>
                      <Typography
                        variant="regular/14-20"
                        color={colorsV2.black80}
                        v3
                      >
                        {eachSkill}
                      </Typography>
                      {skills?.length > 1 && skillIdx < skills?.length - 1 && (
                        <Dot>•</Dot>
                      )}
                    </>
                  ))}
                </Skills>
              </ContentBox>
            )}
          </BookItem>
        );
      })}
    </ListBook>
  );
};

import { css } from 'styled-components';

export const sizeStyles = css`
  &.size-20 {
    font-size: 12px;
    line-height: 20px;
    border-radius: 12px;
    padding: 0 6px;
  }

  &.size-24 {
    font-size: 12px;
    line-height: 20px;
    border-radius: 8px;
    padding: 2px 8px;
  }

  &.size-28 {
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    padding: 4px 10px;
  }

  &.size-32 {
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    padding: 6px 12px;
  }
`;

import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { BookHeader } from 'shared/DolBook/BookHeader';
import { BookExploration } from 'shared/DolBook/BookExploration';
import { BookFeatured } from 'shared/DolBook/BookFeatured';
import { AllDolBooks } from 'shared/DolBook/AllDolBooks';

const IeltsJunior = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Giáo trình độc quyền của DOL"
        slug={location.pathname}
        seoTitle="Giáo trình độc quyền của DOL"
        seoDesc="Đằng sau mỗi trang sách là hàng năm nghiên cứu, thử nghiệm và cải tiến. Bộ giáo trình của DOLEnglish không chỉ là tài liệu học, mà là giải pháp toàn diện giúp hàng ngàn học viên chinh phục tiếng Anh hiệu quả."
        description="Đằng sau mỗi trang sách là hàng năm nghiên cứu, thử nghiệm và cải tiến. Bộ giáo trình của DOLEnglish không chỉ là tài liệu học, mà là giải pháp toàn diện giúp hàng ngàn học viên chinh phục tiếng Anh hiệu quả."
        featureImg="Co9SpB9sSiS6U9t8QKjI"
      />
      <BookHeader />
      <BookExploration />
      <Space>
        <BookFeatured />
        <AllDolBooks />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .all-books-class {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .all-books-class {
      padding: 0px !important;
    }
  }
`;

export default React.memo(IeltsJunior);

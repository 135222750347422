import * as React from 'react';

function IconsCourseContent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16.2717C0 7.43517 7.16344 0.271729 16 0.271729C24.8366 0.271729 32 7.43517 32 16.2717C32 25.1083 24.8366 32.2717 16 32.2717C7.16344 32.2717 0 25.1083 0 16.2717Z"
        fill="#2B52D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1663 7.1051C8.32539 7.1051 6.83301 8.59749 6.83301 10.4384V18.7718C6.83301 20.6127 8.32539 22.1051 10.1663 22.1051L11.833 22.1051C11.833 23.9461 13.3254 25.4384 15.1663 25.4384H21.833C23.674 25.4384 25.1663 23.9461 25.1663 22.1051V13.7718C25.1663 11.9308 23.674 10.4384 21.833 10.4384H20.1663C20.1663 8.59749 18.674 7.1051 16.833 7.1051H10.1663ZM20.1663 12.1051L20.1663 18.7718C20.1663 20.6127 18.674 22.1051 16.833 22.1051L13.4997 22.1051C13.4997 23.0256 14.2459 23.7718 15.1663 23.7718H21.833C22.7535 23.7718 23.4997 23.0256 23.4997 22.1051V13.7718C23.4997 12.8513 22.7535 12.1051 21.833 12.1051H20.1663ZM8.49967 10.4384C8.49967 9.51796 9.24587 8.77177 10.1663 8.77177H16.833C17.7535 8.77177 18.4997 9.51796 18.4997 10.4384V18.7718C18.4997 19.6922 17.7535 20.4384 16.833 20.4384H10.1663C9.24587 20.4384 8.49967 19.6922 8.49967 18.7718V10.4384ZM10.9997 13.7718C10.5394 13.7718 10.1663 14.1449 10.1663 14.6051C10.1663 15.0653 10.5394 15.4384 10.9997 15.4384H13.4997C13.9599 15.4384 14.333 15.0653 14.333 14.6051C14.333 14.1449 13.9599 13.7718 13.4997 13.7718H10.9997ZM10.9997 17.1051C10.5394 17.1051 10.1663 17.4782 10.1663 17.9384C10.1663 18.3987 10.5394 18.7718 10.9997 18.7718H15.1663C15.6266 18.7718 15.9997 18.3987 15.9997 17.9384C15.9997 17.4782 15.6266 17.1051 15.1663 17.1051H10.9997Z"
        fill="white"
        fillOpacity="0.9"
      />
    </svg>
  );
}

export default React.memo(IconsCourseContent);

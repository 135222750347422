import * as React from 'react';

function IconsFeatureBulb(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16.2717C0 7.43517 7.16344 0.271729 16 0.271729C24.8366 0.271729 32 7.43517 32 16.2717C32 25.1083 24.8366 32.2717 16 32.2717C7.16344 32.2717 0 25.1083 0 16.2717Z"
        fill="#329546"
      />
      <path
        d="M9.61707 8.17158C9.37299 7.92751 8.97727 7.92751 8.73319 8.17158C8.48911 8.41566 8.48911 8.81139 8.73319 9.05547L9.72485 10.0471C9.96893 10.2912 10.3647 10.2912 10.6087 10.0471C10.8528 9.80306 10.8528 9.40733 10.6087 9.16325L9.61707 8.17158Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M24.1004 9.8888C24.3444 9.64472 24.3444 9.24899 24.1004 9.00492C23.8563 8.76084 23.4606 8.76084 23.2165 9.00492L22.2248 9.99658C21.9807 10.2407 21.9807 10.6364 22.2248 10.8805C22.4689 11.1245 22.8646 11.1245 23.1087 10.8805L24.1004 9.8888Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8751 21.2799V21.6884C12.8751 23.1845 14.0875 24.3968 15.5835 24.3968H16.4168C17.9128 24.3968 19.1251 23.1845 19.1251 21.6884V19.3826C19.1251 19.3343 19.1509 19.2613 19.2416 19.1972C20.6803 18.1807 21.6251 16.5051 21.6251 14.6051C21.6251 11.2755 18.7307 8.62034 15.3233 9.02019C12.862 9.30855 10.8269 11.2558 10.4451 13.7073C10.0942 15.964 11.0934 18.02 12.7569 19.1955C12.8479 19.26 12.8751 19.3345 12.8751 19.3859V21.2637C12.8751 21.2664 12.8751 21.2691 12.8751 21.2718C12.8751 21.2745 12.8751 21.2772 12.8751 21.2799ZM20.3751 14.6051C20.3751 12.0148 18.1247 9.94996 15.4688 10.2617C13.5619 10.485 11.9752 12.0061 11.6802 13.8995L20.3751 14.6051ZM20.3751 14.6051L11.6802 13.8995C11.4061 15.6627 12.1819 17.2586 13.4783 18.1747C13.5343 18.2143 13.5884 18.258 13.6395 18.3051H18.3608C18.4115 18.2585 18.4648 18.2155 18.5203 18.1763C19.6433 17.3829 20.3751 16.0801 20.3751 14.6051ZM14.1251 19.5551H17.8751V20.6468H14.1251V19.5551ZM14.1399 21.8968H17.8604C17.7593 22.6037 17.1517 23.1468 16.4168 23.1468H15.5835C14.8486 23.1468 14.2409 22.6037 14.1399 21.8968Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M9.77541 18.3299C10.0195 18.5739 10.0195 18.9697 9.77541 19.2138L8.78374 20.2054C8.53966 20.4495 8.14393 20.4495 7.89986 20.2054C7.65578 19.9613 7.65578 19.5656 7.89986 19.3215L8.89152 18.3299C9.1356 18.0858 9.53133 18.0858 9.77541 18.3299Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M22.2248 18.3299C22.4689 18.0858 22.8646 18.0858 23.1087 18.3299L24.1004 19.3215C24.3444 19.5656 24.3444 19.9613 24.1004 20.2054C23.8563 20.4495 23.4606 20.4495 23.2165 20.2054L22.2248 19.2138C21.9807 18.9697 21.9807 18.5739 22.2248 18.3299Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M23.5001 13.9801C23.1549 13.9801 22.8751 14.26 22.8751 14.6051C22.8751 14.9503 23.1549 15.2301 23.5001 15.2301H24.9084C25.2536 15.2301 25.5334 14.9503 25.5334 14.6051C25.5334 14.26 25.2536 13.9801 24.9084 13.9801H23.5001Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M6.4668 14.6051C6.4668 14.26 6.74662 13.9801 7.0918 13.9801H8.50013C8.84531 13.9801 9.12513 14.26 9.12513 14.6051C9.12513 14.9503 8.84531 15.2301 8.50013 15.2301H7.0918C6.74662 15.2301 6.4668 14.9503 6.4668 14.6051Z"
        fill="white"
        fillOpacity="0.9"
      />
    </svg>
  );
}

export default React.memo(IconsFeatureBulb);

import * as React from 'react';

function IconInfoLinearthinkingAmbassador(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16.2717C0 7.43517 7.16344 0.271729 16 0.271729C24.8366 0.271729 32 7.43517 32 16.2717C32 25.1083 24.8366 32.2717 16 32.2717C7.16344 32.2717 0 25.1083 0 16.2717Z"
        fill="#D42525"
      />
      <path
        d="M23.9598 22.3598L21.6868 17.8136C22.4308 16.7163 22.866 15.3934 22.866 13.9706C22.866 10.1849 19.7861 7.1051 16.0005 7.1051C12.2148 7.1051 9.13498 10.1849 9.13498 13.9706C9.13498 15.3934 9.57013 16.7164 10.3142 17.8139L8.04104 22.3598C7.95782 22.5264 7.96677 22.7241 8.06454 22.8824C8.16245 23.0407 8.33534 23.1373 8.5215 23.1373H11.1294L12.6942 25.2236C12.7963 25.3595 12.9557 25.4384 13.1239 25.4384C13.3467 25.4384 13.5222 25.3056 13.6043 25.1415L15.7595 20.8313C15.8395 20.8341 15.9198 20.8361 16.0005 20.8361C16.0812 20.8361 16.1615 20.8341 16.2415 20.8313L18.3966 25.1415C18.4785 25.3053 18.6541 25.4384 18.8771 25.4384C19.0451 25.4384 19.2047 25.3595 19.3066 25.2236L20.8715 23.1373H23.4794C23.6656 23.1373 23.8385 23.0407 23.9363 22.8824C24.0342 22.7241 24.0431 22.5264 23.9598 22.3598ZM13.0322 23.8838L11.8277 22.2779C11.7263 22.1426 11.5671 22.063 11.398 22.063H9.39053L11.0572 18.7299C12.0036 19.7123 13.239 20.4146 14.6253 20.6976L13.0322 23.8838ZM10.2092 13.9706C10.2092 10.7773 12.8072 8.17932 16.0005 8.17932C19.1938 8.17932 21.7917 10.7773 21.7917 13.9706C21.7917 17.1639 19.1938 19.7619 16.0005 19.7619C12.8072 19.7619 10.2092 17.1639 10.2092 13.9706ZM20.6028 22.063C20.4339 22.063 20.2747 22.1426 20.1731 22.2779L18.9687 23.8838L17.3756 20.6976C18.762 20.4145 19.9975 19.7123 20.9438 18.7296L22.6103 22.0629L20.6028 22.063Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M18.7421 14.8617L20.2421 13.0713C20.361 12.9295 20.3985 12.7365 20.3413 12.5605C20.2841 12.3844 20.1403 12.2503 19.9608 12.2054L17.695 11.6386L16.4556 9.65872C16.3574 9.50179 16.1854 9.40653 16.0004 9.40653C15.8153 9.40653 15.6433 9.50179 15.5451 9.65872L14.3059 11.6386L12.0403 12.2054C11.8607 12.2503 11.7169 12.3844 11.6597 12.5604C11.6026 12.7365 11.64 12.9295 11.7589 13.0713L13.2589 14.8617L13.0974 17.1915C13.0847 17.3762 13.1678 17.5544 13.3175 17.6632C13.5448 17.8284 13.776 17.7502 13.834 17.7268L16.0004 16.8538L18.1667 17.727C18.3383 17.7961 18.5334 17.772 18.6831 17.6633C18.8329 17.5545 18.916 17.3763 18.9033 17.1917L18.7421 14.8617ZM17.7797 14.3377C17.6904 14.4443 17.646 14.5811 17.6556 14.7197L17.7724 16.4098L16.2012 15.7765C16.0271 15.7062 15.8743 15.7462 15.7996 15.7765L14.2285 16.4098L14.3455 14.7199C14.355 14.5812 14.3106 14.4443 14.2213 14.3377L13.1334 13.0393L14.7768 12.6282C14.9116 12.5945 15.028 12.5099 15.1017 12.3921L16.0005 10.956L16.8995 12.3921C16.9732 12.5099 17.0895 12.5945 17.2244 12.6282L18.8677 13.0393L17.7797 14.3377Z"
        fill="white"
        fillOpacity="0.9"
      />
    </svg>
  );
}

export default React.memo(IconInfoLinearthinkingAmbassador);

import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import Button from '@dolstaff/shared/es/Button';
import { fromScreen } from 'utils/media-query/responsive.util';
import { ListBookMobile } from './ListBookMobile';
import Lightbox from 'react-image-lightbox';

const Main = styled.div`
  display: grid;
  gap: 40px;
`;
const MainContent = styled.div``;
const GroupBookTitle = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr;
  gap: 12px;
`;
const ListBook = styled.div`
  display: none;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  row-gap: 32px;
  margin-top: 24px;
  ${fromScreen(1144)} {
    display: grid;
  }
`;
const BookItem = styled.div`
  display: grid;
  gap: 12px;
  grid-auto-rows: max-content;
`;
const Skills = styled.div`
  display: flex;
  align-items: center;
`;
const ImageBox = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 336px;
  border-radius: 12px;
  background: ${colorsV2.neutral10};
`;

const CustomImage = styled.div`
  position: relative;
  width: 185px;
  box-shadow: 0px 7.52px 7.52px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: width 200ms ease-in-out;
  img {
    border-radius: 8px;
  }
  :hover {
    width: 193px;
  }
`;

const BindingShadowDouble = styled.div`
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  border-radius: 38px;
  opacity: 0.55;
  background: linear-gradient(
    270deg,
    rgba(136, 124, 200, 0) 0%,
    rgba(40, 24, 125, 0.3) 35.42%,
    rgba(40, 24, 125, 0.3) 64.06%,
    rgba(136, 124, 200, 0) 100%
  );
  filter: blur(0.59px);
  left: 7.63px;
`;
const ContentBox = styled.div``;
const Dot = styled.span`
  color: ${colorsV2.gray40};
  margin: 0 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.neutral20};
`;

export const GroupBook = ({
  groupTitle = '',
  books = [],
  hasDivider = false,
  images = []
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  return (
    <Main>
      <MainContent>
        <GroupBookTitle>
          <LazyImage
            src={
              'https://gqefcpylonobj.vcdn.cloud/directus-upload/9895d71b-ea2b-4126-9c15-679c7b7f7438.png'
            }
            alt={'group-book-icon'}
          />
          <Typography variant="semi-bold/24-32" color={colorsV2.neutral120} v3>
            {groupTitle}
          </Typography>
        </GroupBookTitle>
        <ListBookMobile books={books} />
        <ListBook>
          {books?.map((eachBook, idx) => {
            const {
              imageUrl,
              title,
              skills = [],
              isComingSoon = false
            } = eachBook || {};
            return (
              <BookItem key={idx}>
                <ImageBox>
                  <CustomImage>
                    <LazyImage src={imageUrl} alt={`dol-book-${idx}`} />
                    <BindingShadowDouble />
                  </CustomImage>
                </ImageBox>
                {isComingSoon ? (
                  <Typography
                    variant="semi-bold/20-28"
                    color={colorsV2.neutral120}
                    v3
                  >
                    Sách sắp ra mắt...
                  </Typography>
                ) : (
                  <ContentBox>
                    <Typography
                      variant="semi-bold/20-28"
                      color={colorsV2.black100}
                      v3
                    >
                      {title}
                    </Typography>
                    <Skills>
                      {skills?.map((eachSkill, skillIdx) => (
                        <>
                          <Typography
                            variant="regular/14-20"
                            color={colorsV2.black80}
                            v3
                          >
                            {eachSkill}
                          </Typography>
                          {skills?.length > 1 &&
                            skillIdx < skills?.length - 1 && <Dot>•</Dot>}
                        </>
                      ))}
                    </Skills>
                  </ContentBox>
                )}
              </BookItem>
            );
          })}
        </ListBook>
      </MainContent>
      <Button
        size="large"
        className="btn-action info"
        type="primary"
        marginBottom="0"
        onClick={() => setOpen(true)}
      >
        <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
          Xem sách mẫu
        </Typography>
      </Button>
      {hasDivider && <Divider />}
      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 200000
            }
          }}
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => {
            setOpen(false);
            setIndex(0);
          }}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}
    </Main>
  );
};

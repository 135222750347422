import { colorsV2 } from 'style/colors-v2';
import { innerBorder } from 'style/css-style';
import { css } from 'styled-components';

const colorWhiteOutline = css`
  &.color-white-outline {
    ${innerBorder(`1px solid ${colorsV2.gray20}`)}

    &.color-style-light {
      background-color: ${colorsV2.white100};
      color: ${colorsV2.gray140};
    }

    &.color-style-bold {
      background-color: ${colorsV2.gray10};
      color: ${colorsV2.gray180};
    }
  }
`;

const colorGray = css`
  &.color-gray {
    &.color-style-light {
      background-color: ${colorsV2.gray10};
      color: ${colorsV2.gray160};
    }

    &.color-style-bold {
      background-color: ${colorsV2.gray100};
      color: ${colorsV2.white100};
    }
  }
`;

const colorBlack = css`
  &.color-black {
    &.color-style-light {
      background-color: ${colorsV2.gray20};
      color: ${colorsV2.gray180};
    }

    &.color-style-bold {
      background-color: ${colorsV2.gray180};
      color: ${colorsV2.white100};
    }
  }
`;

const colorRed = css`
  &.color-red {
    &.color-style-light {
      background-color: ${colorsV2.red10};
      color: ${colorsV2.red100};
    }

    &.color-style-bold {
      background-color: ${colorsV2.red100};
      color: ${colorsV2.white100};
    }
  }
`;

const colorYellow = css`
  &.color-yellow {
    &.color-style-light {
      background-color: ${colorsV2.yellow10};
      color: ${colorsV2.yellow120};
    }

    &.color-style-bold {
      background-color: ${colorsV2.yellow100};
      color: ${colorsV2.white100};
    }
  }
`;

const colorGreen = css`
  &.color-green {
    &.color-style-light {
      background-color: ${colorsV2.green10};
      color: ${colorsV2.green100};
    }

    &.color-style-bold {
      background-color: ${colorsV2.green100};
      color: ${colorsV2.white100};
    }
  }
`;

const colorPurple = css`
  &.color-purple {
    &.color-style-light {
      background-color: ${colorsV2.purple10};
      color: ${colorsV2.purple100};
    }

    &.color-style-bold {
      background-color: ${colorsV2.purple100};
      color: ${colorsV2.white100};
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.color-style-light {
      background-color: ${colorsV2.blue10};
      color: ${colorsV2.blue100};
    }

    &.color-style-bold {
      background-color: ${colorsV2.blue100};
      color: ${colorsV2.white100};
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.color-style-light {
      background-color: ${colorsV2.primary10};
      color: ${colorsV2.primary100};
    }

    &.color-style-bold {
      background-color: ${colorsV2.primary100};
      color: ${colorsV2.white100};
    }
  }
`;

export const colorStyles = css`
  ${colorPrimary}
  ${colorBlue}
  ${colorPurple}
  ${colorGreen}
  ${colorYellow}
  ${colorRed}
  ${colorBlack}
  ${colorGray}
  ${colorWhiteOutline}
`;

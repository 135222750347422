/* eslint-disable react/prop-types */

import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';

const SpecialItem = styled.div`
  display: grid;
  gap: 4px;
`;
const SpecicalContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content);
  gap: 6px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SpecialCard = ({ quantity = '', icon = <></>, text = '' }) => {
  return (
    <SpecialItem>
      <Typography variant="bold/48-58" color={colorsV2.white100}>
        {quantity}
      </Typography>
      <SpecicalContent>
        {icon}
        <Typography variant="regular/16-20" color={colorsV2.white80}>
          {text}
        </Typography>
      </SpecicalContent>
    </SpecialItem>
  );
};

import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { GroupBook } from './components/GroupBook';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled.div`
  display: grid;
  gap: 40px;
  .info {
    width: fit-content;
    margin: auto;
    border-radius: 12px;
  }
`;

const HeaderTitle = styled.div`
  width: 100%;
`;

const MainContent = styled.div`
  display: grid;
  gap: 40px;
`;

const AllDolBooksBase = ({ data }) => {
  return (
    <Container id="all-dol-books">
      <HeaderTitle>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Những đầu sách nhà DOL
        </Typography>
      </HeaderTitle>
      <MainContent>
        {data?.map((eachGroupBook, groupIdx) => (
          <GroupBook
            key={groupIdx}
            groupTitle={eachGroupBook?.groupTitle}
            books={eachGroupBook?.books}
            hasDivider={data?.length > 1 && groupIdx < data?.length - 1}
            images={eachGroupBook?.images}
          />
        ))}
      </MainContent>
    </Container>
  );
};

export const AllDolBooks = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "All_Dol_Books" }) {
            content
          }
        }
      }
    `);
    return <AllDolBooksBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {
    className: 'all-books-class'
  }
);
